import React, { useEffect, useRef } from "react";
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  width: 100%;
  height: 100px;
  font-size: 15px;
  border-radius: 6px;
  font-family: 'Inter', sans-serif;
  border: 1px solid;
  padding: 8px 8px;
  min-height: 100px;
  box-sizing: border-box;
  resize: none;

  &:focus-visible {
    outline: #3C1A80 auto 1px;
  }
`;

interface TextareaProps {
  text: string;
  setText: (text: string) => void;
}

function Textarea({ text, setText }: TextareaProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const adjustHeight = () => {
      const element = textareaRef.current;
      if (element) {
        element.style.height = "auto";
        element.style.height = `${element.scrollHeight + 10}px`; 
      }
    };
    adjustHeight();

    const handleLoad = () => adjustHeight();
    window.addEventListener("load", handleLoad);

    return () => window.removeEventListener("load", handleLoad);
  }, [text]);

  return (
    <StyledTextarea
      ref={textareaRef}
      value={text}
      onChange={(e) => setText(e.target.value)}
    />
  );
}

export default Textarea;
