import React, { useState, CSSProperties } from "react";
import Header from "./Header";
import styled from "styled-components";
import { startAutomatedPipeline } from "./client/startAutomatedPipeline";

const StyledInput = styled.input`
  width: 100%;
  height: 42px;
  font-size: 15px;
  border-radius: 6px;
  font-family: Inter, sans-serif;
  border: 1px solid;
  padding: 0px 8px;
  box-sizing: border-box;

  &:focus-visible {
    outline: #3c1a80 auto 1px;
  }
`;

const maincontainer: CSSProperties = {
  background: "#0A031A",
  paddingTop: "80px",
  minHeight: "calc(100vh - 60px)",
  height: "auto",
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingBottom: "50px",
  boxSizing: "border-box",
};

const buttonStyle: CSSProperties = {
  padding: "12px 8px",
  fontFamily: "Inter, sans-serif",
  border: "none",
  width: "100%",
  background: "#7743DB",
  borderRadius: "6px",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "14px",
  color: "white",
};

const container: CSSProperties = {
  display: "flex",
  alignItems: "center",
  maxWidth: "800px",
  margin: "0 auto",
  backgroundColor: "#FFFFFF",
  padding: "15px 25px",
  borderRadius: "6px",
  boxShadow: "0 5px 15px rgba(0,0,0,0.08)",
};

const textBig: CSSProperties = {
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  marginBottom: "4px",
  marginTop: "4px",
};

const baseContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const flexEndContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: "18px",
};

function Pipeline() {
  const [url, setUrl] = useState("");
  const [message, setMessage] = useState("");

  const startPodcastPipeline = async () => {
    try {
      const response = await startAutomatedPipeline(url);
      const id = response.id;
      setMessage(`Podcast created successfully! ID: ${id}`);
      setUrl("")
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } catch (error: any) {
      alert(`Error creating podcast - ${error.response.data}`);
      setMessage("");
    }
  };

  return (
    <div>
      <Header />
      <div style={maincontainer}>
        <div style={{ marginBottom: "30px" }}>
          <div style={container}>
            <div style={baseContainer}>
              <div style={textBig}> Link </div>
              <StyledInput
                value={url}
                id="url"
                placeholder="https://newsarticle.com"
                onChange={(e) => setUrl(e.target.value)}
              />
              {message && <div style={{ marginTop: "20px" }}>{message}</div>}{" "}
              <div style={flexEndContainer}>
                <button onClick={startPodcastPipeline} style={buttonStyle}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Pipeline;
