import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Container, TextField, Typography } from "@mui/material";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [incorrectPassword, setIncorrectPassword] = useState(false);

  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/home");
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode);
        console.log(errorMessage);
        if (errorCode === "auth/invalid-login-credentials") {
          setIncorrectPassword(true);
        }
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/home");
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  return (
    <div
      style={{
        background: "#0A031A",
        paddingTop: "20px",
        height: "100vh",
        paddingLeft: "30px",
        paddingRight: "30px",
        boxSizing: "border-box",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "5rem",
          background: "white",
          padding: "40px",
          borderRadius: "20px",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          fontWeight={600}
          fontFamily={"Inter, sans-serif"}
          mb={2}
        >
          PocketPod Developer Portal
        </Typography>
        <form>
          <TextField
            fullWidth
            id="email-address"
            name="email"
            type="email"
            required
            label="Email address"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            fullWidth
            id="password"
            name="password"
            type="password"
            required
            label="Password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onLogin}
            sx={{ marginTop: "1rem" }}
            style={{
              background: "#7743DB",
              fontSize: "16px",
              fontWeight: 600,
              fontFamily: "Inter, sans-serif",
            }}
          >
            Login
          </Button>
        </form>
        {incorrectPassword && (
          <Typography
            variant="body2"
            align="center"
            color="error"
            fontFamily={"Inter, sans-serif"}
            fontWeight={600}
            mt={2}
          >
            Incorrect password.{" "}
            <NavLink
              to="/forgot-password"
              style={{ textDecoration: "underline" }}
            >
              Forgot password?
            </NavLink>
          </Typography>
        )}
      </Container>
    </div>
  );
};

export default Login;
