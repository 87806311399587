import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import { API_BASE_URL_SUMMARIZER_BACKEND } from '../utilities/constants';

interface CohereResponse {
    episode: string;
}

export const combineTranscripts = async (transcriptArray: string[]): Promise<string> => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not authenticated');
  }

  const idToken = await getIdToken(user);
  const headers = { Authorization: `Bearer ${idToken}` };

  const response = await axios.post<CohereResponse>(
    `${API_BASE_URL_SUMMARIZER_BACKEND}/cohere`, 
    { transcriptArray }, 
    { headers }
  );

  return response.data.episode;
};