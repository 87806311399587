/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import Textarea from "./components/Textarea";
import Dropdown from "./components/Dropdown";
import { voiceOptions } from "./utilities/constants";
import ReactAudioPlayer from "react-audio-player";
import styled from "styled-components";
import { debounce } from "./utilities/utility";

const StyledInput = styled.input`
  width: 100%;
  height: 42px;
  font-size: 15px;
  border-radius: 6px;
  font-family: Inter, sans-serif;
  border: 1px solid;
  padding: 0px 8px;
  box-sizing: border-box;

  &:focus-visible {
    outline: #3c1a80 auto 1px;
  }
`;

interface FinalizePodcastProps {
  loading: boolean;
  metadata: PodcastMetadata;
  createPodcast: (
    voices: Map<string, string>,
    dialog: Dialog[],
    script: string,
    prompt: string,
    podcastId?: string
  ) => void;
  setCompletedPodcast: (podcast: any) => void;
  save: (podcast: Podcast) => void;
  resetAudio: (e: any) => void;
}

interface SpeakerStats {
  [speaker: string]: {
    lines: number;
    words: number;
  };
}

interface PodcastMetadata {
  audioUrl: string;
  category: string;
  coverArtUrl: string;
  tags: string[];
  title: string;
  summary: string;
  podcastTitle: string;
  podcastSummary: string;
  voices: Record<string, string>;
  transcript: string;
  prompt: string;
  podcastId?: string;
}

interface Podcast {
  id?: string;
  category?: string;
  style?: Style;
  title?: string;
  summary?: string;
  coverArt?: string;
  episodes: Episode[];
}

interface Episode {
  audioURL: string;
  tags: string[];
  title: string;
  summary: string;
  transcript: string;
}

interface Style {
  voices: Record<string, string>;
  prompt: string;
}

interface Dialog {
  speaker: string;
  dialogue: string;
}

const buttonStyle: CSSProperties = {
  padding: "12px 8px",
  fontFamily: "Inter, sans-serif",
  border: "none",
  width: "100%",
  background: "#7743DB",
  borderRadius: "6px",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "14px",
  color: "white",
};

const buttonStyleLeft: CSSProperties = {
  padding: "12px 8px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  background: "white",
  marginRight: "10px",
  borderRadius: "6px",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "14px",
  color: "black",
  border: "1px solid black",
};

const flexEndContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: "18px",
};

const baseContainerWithMargin: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "10px",
  marginBottom: "5px",
};

const textSmall: CSSProperties = {
  fontSize: "14px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  fontWeight: "500",
  marginBottom: "4px",
  marginTop: "2px",
};

const container: CSSProperties = {
  display: "flex",
  alignItems: "center",
  maxWidth: "1200px",
  margin: "0 auto",
  backgroundColor: "#FFFFFF",
  padding: "15px 25px",
  borderRadius: "6px",
  boxShadow: "0 5px 15px rgba(0,0,0,0.08)",
};

const textBig: CSSProperties = {
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  marginBottom: "4px",
  marginTop: "6px",
  width: "100%",
};

const image: CSSProperties = {
  borderRadius: "8px",
  marginBottom: "8px",
};

const textBigger: CSSProperties = {
  fontSize: "18px",
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  marginBottom: "25px",
};

const baseContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const spacer: CSSProperties = {
  height: "15px",
  width: "100%",
};

const baseContainerCenter: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
};

const innerContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: "30px",
  marginBottom: "30px",
};

const extractDialogues = (finalScript: string) => {
  const dialogues = [];
  const regex = /\[(SPEAKER\d+)\]:\s(.*?)(\n|$)/g;

  let match;
  while ((match = regex.exec(finalScript)) !== null) {
    dialogues.push({ speaker: match[1], dialogue: match[2] });
  }

  return dialogues;
};

function countWords(text: string, includeSpeaker: boolean) {
  let cleanedText = text;
  if (!includeSpeaker) {
    cleanedText = text.replace(/\[SPEAKER\d+\]:/g, "");
  }
  const words = cleanedText.replace(/[^\w\s]/gi, "").split(/\s+/);
  return words.filter((word) => word.trim() !== "").length;
}

function FinalizePodcast({
  loading,
  metadata,
  createPodcast,
  setCompletedPodcast,
  save,
  resetAudio,
}: FinalizePodcastProps) {
  const [voices, setVoices] = useState<Map<string, string>>(
    new Map(Object.entries(metadata.voices ?? []))
  );
  const debouncedUpdateLocalStorage = debounce((key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
  }, 1000);

  const updatePodcastProperty = useCallback(
    (propertyKey: string, value: any) => {
      setCompletedPodcast((data: any) => {
        const updatedData = { ...data, [propertyKey]: value };
        debouncedUpdateLocalStorage("completedPodcast", updatedData);
        return updatedData;
      });
    },
    [debouncedUpdateLocalStorage, setCompletedPodcast]
  );

  const handleSelect = (item: string, value?: string) => {
    setVoices((prev) => new Map(prev).set(value ?? "", item));
  };
  const dialogues = useMemo(
    () => extractDialogues(metadata.transcript ?? ""),
    [metadata.transcript]
  );
  const speakers = useMemo(
    () => new Set(dialogues.map((x) => x.speaker)),
    [dialogues]
  );
  const speakerStats = useMemo(() => {
    const stats: SpeakerStats = {};

    dialogues.forEach(({ speaker, dialogue }) => {
      if (!stats[speaker]) {
        stats[speaker] = { lines: 0, words: 0 };
      }
      stats[speaker].lines += 1;
      stats[speaker].words += countWords(dialogue, true);
    });

    return stats;
  }, [dialogues]);
  const totalWords = useMemo(
    () =>
      Object.values(speakerStats).reduce(
        (sum, current) => sum + current.words,
        0
      ),
    [speakerStats]
  );

  const savePodcast = useCallback(async () => {
    const episode: Episode[] = [
      {
        audioURL: metadata.audioUrl,
        tags: metadata.tags,
        title: metadata.title,
        summary: metadata.summary,
        transcript: metadata.transcript,
      },
    ];

    let podcast: Podcast = {
      episodes: episode,
    };

    if (metadata.podcastId) {
      podcast = { ...podcast, id: metadata.podcastId };
    } else {
      podcast = {
        ...podcast,
        category: metadata.category,
        style: { prompt: metadata.prompt, voices: metadata.voices },
        coverArt: metadata.coverArtUrl,
        title: metadata.podcastTitle,
        summary: metadata.podcastSummary,
      };
    }

    save(podcast);
  }, [metadata, save]);

  const handleSubmit = useCallback(() => {
    let newVoices = voices;
    if (voices.size !== speakers.size) {
      newVoices = new Map(voices);
      while (newVoices.size < speakers.size) {
        const randomIndex = Math.floor(Math.random() * voiceOptions.length);
        const voice = voiceOptions[randomIndex];
        newVoices.set("SPEAKER" + (newVoices.size + 1), voice.item);
      }
    }
    createPodcast(
      newVoices,
      dialogues,
      metadata.transcript,
      metadata.prompt,
      metadata.podcastId
    );
  }, [dialogues, metadata, createPodcast, speakers.size, voices]);

  return (
    <div style={container}>
      {(loading || metadata.category) && (
        <div style={baseContainer}>
          {loading && (
            <div style={innerContainer}>
              <div style={textBig}> Please Wait </div>
              <div style={textSmall}> Generating Podcast Script...</div>
            </div>
          )}
          {metadata.category && !loading && (
            <div>
              <div style={textBigger}> Review Podcast</div>
              <div style={baseContainerCenter}>
                {!metadata.podcastId && (
                  <div style={innerContainer}>
                    <div style={textBig}> Podcast Title</div>
                    <StyledInput
                      value={metadata.podcastTitle}
                      id="title"
                      onChange={(e) =>
                        updatePodcastProperty("podcastTitle", e.target.value)
                      }
                    />
                    <div style={textBig}>Podcast Summary</div>
                    <Textarea
                      text={metadata.podcastSummary}
                      setText={(e) =>
                        updatePodcastProperty("podcastSummary", e)
                      }
                    />
                  </div>
                )}
                {metadata.podcastId && (
                  <div>
                    <div style={textBig}> Podcast Title</div>
                    <div style={textSmall}> {metadata.podcastTitle}</div>
                    <div style={textBig}> Podcast Summary</div>
                    <div style={textSmall}> {metadata.podcastSummary}</div>
                  </div>
                )}
                <div style={textBig}> Epsiode Title</div>
                <StyledInput
                  value={metadata.title}
                  id="title"
                  onChange={(e) =>
                    updatePodcastProperty("title", e.target.value)
                  }
                />
                <div style={textBig}>Episode Summary</div>
                <Textarea
                  text={metadata.summary}
                  setText={(e) => updatePodcastProperty("summary", e)}
                />
                <div style={spacer} />
                <img
                  style={image}
                  src={metadata.coverArtUrl}
                  height={256}
                  width={256}
                />
                <ReactAudioPlayer src={metadata.audioUrl} controls />
                <div style={textSmall}> {metadata.category}</div>
                <div style={textSmall}>
                  {" "}
                  {metadata?.tags.map((tag, index) => (
                    <i key={index}>{tag + ", "}</i>
                  ))}
                </div>
              </div>
              <div style={flexEndContainer}>
                <button onClick={resetAudio} style={buttonStyleLeft}>
                  Edit Final Script
                </button>
                <button onClick={savePodcast} style={buttonStyle}>
                  Upload
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {!loading && !metadata.category && metadata.transcript && (
        <div style={baseContainer}>
          <div style={textBig}>Final Podcast Script</div>
          <Textarea
            text={metadata.transcript}
            setText={(e) => updatePodcastProperty("transcript", e)}
          />
          {Array.from(speakers).map((speaker, index) => (
            <div style={baseContainerWithMargin} key={index}>
              <div style={textBig}> {speaker} </div>
              <div style={textSmall}>
                {" "}
                <b>Voice</b>{" "}
              </div>
              {metadata.podcastId ? (
                <div>
                  <Dropdown
                    options={voiceOptions.filter(
                      (option) => voices.get(speaker) === option.item
                    )}
                    onSelect={handleSelect}
                    value={speaker}
                  />
                </div>
              ) : (
                <div>
                  <Dropdown
                    options={voiceOptions.filter(
                      (option) =>
                        !Array.from(voices.values()).includes(option.item) ||
                        voices.get(speaker) === option.item
                    )}
                    onSelect={handleSelect}
                    value={speaker}
                  />
                </div>
              )}
              <div style={textSmall}>
                {" "}
                <b>Number of lines:</b> {speakerStats[speaker].lines}{" "}
              </div>
              <div style={textSmall}>
                {" "}
                <b>Number of words:</b> {speakerStats[speaker].words}{" "}
              </div>
              <div style={textSmall}>
                {" "}
                <b>Estimated Speaking Time:</b>{" "}
                {speakerStats[speaker].words / 2.5} seconds{" "}
              </div>
            </div>
          ))}
          <div style={baseContainerWithMargin}>
            <div style={textSmall}>
              <b>Verified Script Format:</b>{" "}
              {countWords(metadata.transcript, false) === totalWords
                ? "True"
                : "False"}
            </div>
            <div style={textSmall}>
              {" "}
              <b>Estimated Podcast Time:</b> {totalWords / 2.5} seconds{" "}
            </div>
          </div>
          <div style={flexEndContainer}>
            <button onClick={handleSubmit} style={buttonStyle}>
              Generate Audio & Metadata
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FinalizePodcast;
