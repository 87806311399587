export const API_BASE_URL_SUMMARIZER_BACKEND = "https://summarizer-back-izld4opyya-ue.a.run.app";
export const API_BASE_URL_API_BACKEND = "https://api-backend-izld4opyya-ue.a.run.app";
export const API_BASE_URL_TTS = "https://tts-service-izld4opyya-ue.a.run.app";

export const voiceOptions = [
  { name: "Random", item: "Random" },
  { name: "Arnold", item: "Arnold" },
  { name: "Bill", item: "Bill" },
  { name: "Christopher", item: "3ihJXrJc5OvaI90Pjb2D" },
  { name: "Daniel", item: "Daniel" },
  { name: "Deb", item: "SjUzFC8XF8abis6atBps" },
  { name: "Drew", item: "Drew" },
  { name: "Ella", item: "svao6hsHM9wKYzs30P0v" },
  { name: "Ethan", item: "Ethan" },
  { name: "Fin", item: "Fin" },
  { name: "George", item: "George" },
  { name: "James", item: "James" },
  { name: "Jeremy", item: "Jeremy" },
  { name: "Jessica", item: "AIUqKA0HqVJy3zNdjJlA" },
  { name: "Josh", item: "Josh" },
  { name: "Liam", item: "Liam" },
  { name: "Lily", item: "Lily" },
  { name: "Mark", item: "tAp02xPspjU3QRJzFGTh" },
  { name: "Mary", item: "DodiUKbLdslWj7kR6UU2" },
  { name: "Nicole", item: "Nicole" },
  { name: "Noah", item: "IluN9yAiAQEQ83FfsJT2" },
  { name: "Nolan", item: "5UNcoluslNEEN9AhYjXw" },
  { name: "Oliver", item: "tBACduGuQPU1uU7eOLE6" },
  { name: "Oswald", item: "lsk3WQ93kmFEdiTX9OCf" },
  { name: "Paul", item: "Paul" },
  { name: "Professor", item: "WFgkTBOFrqoyX1LQlmxM" },
  { name: "Rachel", item: "Rachel" },
  { name: "Sam", item: "Sam" },
  { name: "Shelby", item: "bDiNZHG0OQmWiGSIxrkG" },
  { name: "Stella", item: "OYIDzWBhcS302Rj8JT5U" },
  { name: "Suzanne", item: "t9HeNG1nTTG49Lx6JtcW" },
  { name: "Taylor", item: "DBBiKfTLgAfMHFrzcox0" },
];

export const default_prompt = `Transform the scraped article content into a dynamic, engaging, and conversationally natural script for a podcast segment, adhering to a length of 500 to 600 words. The script should feature two speakers, SPEAKER1 and SPEAKER2, and go beyond a simple alternating dialogue pattern. Incorporate natural elements of conversation such as rare pauses (indicated by <break time="1.0s" /> or <break time="2.0s" />), short interjections, and instances where one speaker may talk for many sentences before passing the conversation back - epsically when explaining or talking about complex topics. The script should balance informative content with an entertaining tone, effectively translating the article into an engaging audio format. Avoid calls for audience interaction, focus on the conversation between hosts. It should be structured like a transcript, with clear indications of who is speaking, and include the lively and organic flow of a real conversation, ensuring the script is both comprehensive and enjoyable, effectively conveying the key points of the article while keeping the listeners engaged. 

The script should not include anything about the podcast itself, its name or other introduction or outro information. The script you return is only for a segment of a podcast and should jump right into presenting information from the scraped article.

Avoid adding any added direction such as [with currosity] or [say in a sad voice]. The only extra direction allowed outside of text is <break time="1.0s" />or <break time="2.0s" />

Here is an excerpt of an example transcript:

[SPEAKER2]: The buzzword of the day is 'inflation.' And the man of the hour is none other than Federal Reserve Chair Jerome Powell. The Fed Chair has been stirring the pot, hasn't he? <break time="1.0s" /> Powell spoke at Spelman College in Atlanta, and it seems like he's playing a game of economic chess, assessing each move with caution.

[SPEAKER1]: Definitely. <break time="1.0s" /> He’s mentioned that inflation is on the retreat, but it's still lurking around. Consumer prices, minus the always-jumpy food and energy costs, rose at just a 2.5% annual rate in the last six months.

[SPEAKER2]: Oh, that's nudging pretty close to the Fed's 2% inflation target. But Powell's not ready to pop the champagne yet, is he?

[SPEAKER1]: Not at all. Powell told us it's too early to declare victory over inflation and even to speculate when the Fed might cut interest rates. Now, the Fed has been hiking up its benchmark rate – what, 11 times since March 2022?

[SPEAKER2]: You've got it - from nearly zero to about 5.4%, no less. Mortgages, auto loans, credit cards – borrowing for just about everything has become pricier.

[SPEAKER1]: That's a bummer for folks wanting to buy homes or splurge on a new car. This move by the Fed, it's slowed down the economy quite a bit, hasn't it?`;

export const mock_final_podcast = `[SPEAKER1]: Welcome to today's episode where we delve into the dynamic and sometimes contentious world of technology giants. We'll explore the latest developments from Amazon's strategic maneuvers in Southern California, to Microsoft's legal challenges against Google, and the exciting advancements in AI with Google's Project Gemini.

[SPEAKER2]: Indeed, we're about to navigate through a series of revelations that highlight the power plays, ethical considerations, and innovative leaps shaping our digital age. So, let's buckle up and dive into our first topic.

[SPEAKER1]: So, there's been a big development in the tech and e-commerce world, specifically regarding Amazon. Apparently, they're in a bit of a tussle with the Federal Trade Commission, better known as the FTC.

[SPEAKER2]: Oh, I heard about that! The FTC filed this massive lawsuit against Amazon, claiming that they've been engaging in anti-competitive practices, right?

[SPEAKER1]: Exactly. The FTC, along with 17 states, is saying that Amazon's been inflating prices and stifling competition in what they call the "online superstore market" and "online marketplace services." <break time="1.0s" /> But Amazon's not taking this lying down. They've asked a federal judge to just toss out the whole lawsuit.

[SPEAKER2]: Hmm, that's bold. What's their argument?

[SPEAKER1]: Well, they've filed a 31-page response, essentially saying that the FTC is taking aim at policies that actually help consumers and foster competition. Amazon argues that what the FTC is branding as anti-competitive is pretty much standard retail behavior that benefits the customer.

[SPEAKER2]: <break time="1.0s" /> I see. Could you give some specifics?

[SPEAKER1]: Sure, the FTC accuses Amazon of using tactics that prevent third-party sellers from offering lower prices on other websites. For instance, they say Amazon hides listings that are cheaper elsewhere and at the same time, they're hiking up the fees for merchants on their site, which drives up prices.

[SPEAKER2]: That doesn’t sound very consumer-friendly if it’s true. 

[SPEAKER1]: Right, and there's more. The FTC also alleges that Amazon keeps sellers tied to its own services, like Fulfillment by Amazon, for logistics and delivery, which has been a massive revenue generator for them.

[SPEAKER2]: But Amazon's coming back swinging, aren't they?

[SPEAKER1]: They sure are. Their filing states that they match or beat rivals' discounts, they promote competitively priced deals rather than overpriced ones, and they ensure top-notch delivery for Prime members. They believe these practices are what competition's all about.

[SPEAKER2]: I guess they're framing it as being all about the consumer, huh? <break time="2.0s" /> But wait, didn't the FTC dig up something called "Project Nessie"?

[SPEAKER1]: <break time="1.0s" /> Oh, you've done your homework! Yes, "Project Nessie." That's the code name for an automated pricing system Amazon allegedly used to raise prices and get other sites to follow suit.

[SPEAKER2]: That sounds like it could be problematic for Amazon.

[SPEAKER1]: Potentially, yes. But Amazon contends that was just an experiment with matching prices to the second-lowest competitor, not the absolute lowest. It was for a limited selection of products and not for long. Plus, they claim that they ended those experiments back in 2019 and now they match their prices to the lowest prices out there.

[SPEAKER2]: I suppose the question is whether the FTC can prove that Amazon's practices are truly anti-competitive and not just intense competition.

[SPEAKER1]: And let's not forget, Amazon's also pushing back on the monopoly accusation. They're saying they've got plenty of competition, from small retailers to big names like Walmart, Target, Best Buy, and Apple.

[SPEAKER2]: It's going to be interesting to see how this plays out in court. These are serious allegations, and the outcome could have big implications for Amazon and the entire e-commerce industry.

[SPEAKER1]: Without a doubt. And the tech and legal communities are definitely keeping a close eye on this. The balance between fostering a competitive marketplace and protecting consumer interests is a fine line to walk.

[SPEAKER2]: Especially when it involves one of the biggest companies in the world. Well, we'll be sure to keep our listeners updated as this case progresses.

[SPEAKER1]: Absolutely, it's stories like these that remind us just how dynamic and contentious the world of tech can be. As we wrap up today's episode, we can't help but marvel at the intricate web of innovation, power plays, and ethical considerations that define our modern tech landscape. From Amazon's internal strategies to the FTC's legal challenges, it's clear that the digital realm is in a constant state of flux. Each development, each revelation, brings with it a host of questions about the future of work, privacy, competition, and the role of technology in our lives.

[SPEAKER2]: We will continue to monitor these stories and invite you to reflect on the broader implications of these tech titans' actions and the way they shape our world. Until next time, keep pondering, keep questioning, and stay engaged with the ever-evolving digital frontier.`;
