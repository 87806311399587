import axios from 'axios';
import { getAuth, onAuthStateChanged, getIdToken } from 'firebase/auth';
import { API_BASE_URL_API_BACKEND } from '../utilities/constants';

interface Original {
  title: string;
  id: string;
  style: Style;
}

interface Style {
  prompt: string;
  voices: Record<string, string>;
}

export const fetchOriginals = async (): Promise<Original[]> => {
  const auth = getAuth();

  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const idToken = await getIdToken(user);
          const headers = { Authorization: `Bearer ${idToken}` };
          const contentResponse = await axios.get<Original[]>(
            `${API_BASE_URL_API_BACKEND}/originals`,
            { headers }
          );
          resolve(contentResponse.data);
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error('User not authenticated'));
      }
    });
  });
};