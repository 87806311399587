import { useState } from "react";
import { passwordReset } from "./firebase";
import { NavLink } from "react-router-dom";
import { TextField, Button, Container } from "@mui/material";

  function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [emailMessage, setEmailMessage] = useState(false)

    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        await passwordReset(email);
        setEmailMessage(true);
      } catch (error) {    
        if (error.code === 'auth/user-not-found') {
          alert('User not found, try again!')
          setEmail('')
        }
      }
    };
    
    // RESET PASSWORD PAGE NOT NEEDED
    return (
        <div
        style={{
          background:"#0A031A",
          paddingTop: "20px",
          height: "100vh",
          paddingLeft: "30px",
          paddingRight: "30px",
          boxSizing: "border-box",
        }}
        >
        <Container maxWidth="sm" sx={{ marginTop: '5rem', background: 'white', padding: "40px", borderRadius: '20px' }}>
        {
          emailMessage ?
          <>
          <h3>Reset password email has been sent</h3>
          <NavLink to="/" style={{ textDecoration: 'underline' }}>Return to home</NavLink>
          </>
          : 
          <form onSubmit={handleSubmit}>
            <TextField
                    fullWidth
                    id="email-address"
                    name="email"
                    type="email"
                    required
                    label="Email address"
                    variant="outlined"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            {/* <input 
              type="email" 
              name="email"
              placeholder="name@email.com"
              onChange={(e) => setEmail(e.target.value)}
              required
            /> */}
            {/* <div>
              <button type='submit'>Reset Your Password</button>
            </div> */}
            <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    // onClick={onLogin}
                    sx={{ marginTop: '1rem' }}
                    style={{
                        background: "#7743DB",
                        fontSize: '16px',
                        fontWeight: 600,
                        fontFamily:"Inter, sans-serif"
                      }}
                >
                    Reset your password
                </Button>
          </form>
        }
        </Container>
      </div>
    )
  }

  export default ForgotPassword