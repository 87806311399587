export function mapToObject<T>(map: Map<string, T>): Record<string, T> {
  const obj: Record<string, T> = {};
  for (const [key, value] of map) {
    obj[key] = value;
  }
  return obj;
};

export function debounce<T extends (...args: any[]) => any>(func: T, wait: number): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return function(...args: Parameters<T>): void {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func(...args), wait);
  };
}

export function updateStateAndLocalStorage<T>(data: T, setState: React.Dispatch<React.SetStateAction<T>>, localStorageKey: string): void {
  // Update the state with the new data
  setState(data);

  // Convert the data to a JSON string and store it in local storage
  localStorage.setItem(localStorageKey, JSON.stringify(data));
}

