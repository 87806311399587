import React, { useEffect, useState, CSSProperties } from 'react';
import { User, getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { useNavigate, useLocation } from 'react-router-dom';

const headerStyle: CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    height: '60px',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingTop: "10px",
    background: "#0A031A",
    boxSizing: 'border-box',
    top: 0,
    left: 0,
    zIndex: 1000, 
  };

  const buttonGroupStyle: CSSProperties = {
    display: 'flex',
    marginTop: "10px",
    marginRight: '10px',
    alignItems: 'center',
  };

  const buttonStyleFilled: CSSProperties = {
    padding: '12px 16px',
    fontFamily:"Inter, sans-serif",
    border: 'none',
    width: '100px',
    background: 'white',
    borderRadius: '4px',
    height: "40px",
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: '14px',
    color: "black"
  };

  const buttonStyleFilledLeft: CSSProperties = {
    padding: '12px 16px',
    fontFamily:"Inter, sans-serif",
    border: 'none',
    width: '150px',
    background: 'white',
    borderRadius: '4px',
    marginRight: '10px',
    height: "40px",
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: '14px',
    color: "black"
  };

  export function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const auth = getAuth();
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          setUser(currentUser);
        } else {
          setUser(null);
        }
      });
      return () => unsubscribe();
    }, [auth]);

    const navigateToPipeline = () => {
      location.pathname.toLowerCase().includes("home") ? navigate('/pipeline') : navigate('/home')
    };

    const navigateToHome = () => {
      navigate('/home')
    };

    const signout = () => {
      signOut(auth).then(() => {
        navigate('/login')
      }).catch((error) => {
        console.error('Error signing out: ', error);
      });
    }
    return (
      <header style={headerStyle}>
        <div style={buttonGroupStyle}>
          <img onClick={navigateToHome} width='200' height='40' src={'/logo.png'} alt='logo'></img>
        </div>
          {user ?
            <div style={buttonGroupStyle}>
              <button onClick={navigateToPipeline}style={buttonStyleFilledLeft}>{location.pathname.toLowerCase().includes("home") ? "Test Pipeline" : "Create Originals"}</button>
              <button onClick={signout}style={buttonStyleFilled}>Signout</button>
            </div>
          :
          <div/>
        }
      </header>
    );
  };

export default Header;