import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { default_prompt } from "./utilities/constants";
import Dropdown from "./components/Dropdown";
import Textarea from "./components/Textarea";
import styled from "styled-components";
import { fetchOriginals } from "./client/fetchOriginals";

const StyledInput = styled.input`
  width: 100%;
  height: 42px;
  font-size: 15px;
  border-radius: 6px;
  font-family: Inter, sans-serif;
  border: 1px solid;
  padding: 0px 8px;
  box-sizing: border-box;

  &:focus-visible {
    outline: #3c1a80 auto 1px;
  }
`;

interface Segment {
  url: string;
  title: string;
  transcript: string;
  prompt: string;
  podcastId: string
}

interface Original {
  title: string;
  id: string;
  style?: Style;
}

interface Style {
  prompt: string;
  voices: Record<string, string>;
}

interface AddToScriptProps {
  loading: boolean;
  podcast: Segment[];
  segment: Segment;
  startNewPodcastTranscript: (podcastId: string, voices?: Record<string, string>) => void;
  combineSegments: (e: any) => void;
  resetPodcast: () => void;
  deleteSegment: (segment: Segment) => void;
  setUrl: (url: string) => void;
  setPrompt: (prompt: string) => void;
}

const buttonStyle: CSSProperties = {
  padding: "12px 8px",
  fontFamily: "Inter, sans-serif",
  border: "none",
  width: "100%",
  background: "#7743DB",
  borderRadius: "6px",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "14px",
  color: "white",
};

const buttonStyleSmall: CSSProperties = {
  padding: "6px",
  fontFamily: "Inter, sans-serif",
  border: "none",
  width: "30px",
  background: "#FF7038",
  borderRadius: "30px",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "14px",
  color: "white",
};

const buttonStyleLeft: CSSProperties = {
  padding: "12px 8px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  background: "white",
  marginRight: "10px",
  borderRadius: "6px",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "14px",
  color: "black",
  border: "1px solid black",
};

const container: CSSProperties = {
  display: "flex",
  alignItems: "center",
  maxWidth: "800px",
  margin: "0 auto",
  backgroundColor: "#FFFFFF",
  padding: "15px 25px",
  borderRadius: "6px",
  boxShadow: "0 5px 15px rgba(0,0,0,0.08)",
};

const innerContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
  marginTop: "30px",
  marginBottom: "30px",
};

const textBig: CSSProperties = {
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  marginBottom: "4px",
  marginTop: "4px",
};

const textSmall: CSSProperties = {
  fontSize: "14px",
  fontFamily: "Inter, sans-serif",
  fontWeight: "500",
  marginBottom: "4px",
};

const baseContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

const baseContainerWithMargin: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "5px",
  marginBottom: "5px",
};

const baseContainerWithMoreMargin: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  marginTop: "35px",
};

const flexEndContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: "18px",
};

const spaceBetween: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
};

const flexEndContainerWithMoreMargin: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: "35px",
};

const podcastContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  marginTop: "10px",
};

function AddToScript({
  loading,
  startNewPodcastTranscript,
  setUrl,
  setPrompt,
  podcast,
  segment,
  deleteSegment,
  combineSegments,
  resetPodcast,
}: AddToScriptProps) {
  const supportedStyles = [
    "Default",
    "Funny",
    "Educational",
    "Mixture",
    "Serious",
  ];
  const options = [
    { name: "Default", item: "Default" },
    { name: "Custom", item: default_prompt },
  ];
  const [originals, setOrginals] = useState<Original[]>([
    { title: "None", id: "None", style: undefined },
  ]);
  const [selectedPodcast, setSelectedPodcast] = useState<string>("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchOriginals();
        setOrginals((originals) => [...originals, ...data]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const startNewPodcastScript = useCallback(() => {
    const voices = originals?.filter((original) => original.id === selectedPodcast || original.id === podcast[0]?.podcastId)[0]?.style?.voices
    startNewPodcastTranscript(selectedPodcast, voices);
  }, [originals, podcast, selectedPodcast, startNewPodcastTranscript]);

  const resetPrompt = useCallback(() => {
    if (selectedPodcast || podcast[0].podcastId) {
      const prompt = originals.filter((original) => original.id === selectedPodcast || original.id === podcast[0].podcastId)[0].style?.prompt ?? "Default"
      setPrompt(prompt)
    } else {
      setPrompt(default_prompt)
    }
  }, [originals, podcast, selectedPodcast, setPrompt]);

  const handleSelect = (item: string) => {
    setPrompt(item);
  };

  const handlePodcastSelect = (item: string) => {
    setSelectedPodcast(item);
    setPrompt(originals.filter((original) => original.id === item)[0].style?.prompt ?? "Default")
  };

  return (
    <div style={container}>
      {loading && (
        <div style={innerContainer}>
          <div style={textBig}> Please Wait </div>
          <div style={textSmall}> Generating Podcast Script...</div>
        </div>
      )}
      {!loading && (
        <div style={baseContainer}>
          <div style={textBig}> Link </div>
          <StyledInput
            value={segment?.url}
            id="url"
            placeholder="https://newsarticle.com"
            onChange={(e) => setUrl(e.target.value)}
          />
          <div style={baseContainerWithMargin}>
            {podcast.length === 0 && (
              <div>
                <div style={textBig}> Podcast (Optional) </div>
                <Dropdown
                  options={originals.map((original) => ({
                    name: original.title,
                    item: original.id,
                  }))}
                  onSelect={handlePodcastSelect}
                />
                <div style={textBig}> Style </div>
                <Dropdown
                  options={
                    podcast.length > 0 || selectedPodcast.length > 0
                      ? options.filter((option) => option.name === "Custom" || segment.prompt === "Default")
                      : options
                  }
                  onSelect={handleSelect}
                />
              </div>
            )}
            {podcast.length > 0 && podcast[0].podcastId && (
              <div>
                <div style={textBig}>Podcast</div>
                <div style={textSmall}> {originals.filter((x) => x.id === podcast[0].podcastId)[0]?.title}</div>
              </div>
            )}
            {!supportedStyles.includes(segment?.prompt) && (
              <div style={baseContainerWithMargin}>
                <div style={spaceBetween}>
                  <div style={textBig}> Custom Style </div>
                  <div onClick={resetPrompt} style={textBig}> Reset </div>
                </div>
                <Textarea
                  text={segment?.prompt}
                  setText={(e) => setPrompt(e)}
                />
              </div>
            )}
          </div>
          <div style={flexEndContainer}>
            <button onClick={startNewPodcastScript} style={buttonStyle}>
              {podcast.length === 0 ? "Start Script" : "Add To Script"}
            </button>
          </div>
          {podcast.length > 0 && (
            <div style={baseContainerWithMoreMargin}>
              <div style={textBig}> Segments in Current Podcast</div>
              {podcast.map((segment, index) => (
                <div style={podcastContainer} key={index}>
                  <div style={textSmall}>{segment.title}</div>
                  <button
                    onClick={() => deleteSegment(segment)}
                    style={buttonStyleSmall}
                  >
                    X
                  </button>
                </div>
              ))}
              <div style={flexEndContainerWithMoreMargin}>
                <button onClick={resetPodcast} style={buttonStyleLeft}>
                  Reset Podcast
                </button>
                <button onClick={combineSegments} style={buttonStyle}>
                  Podcastify
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default AddToScript;
