import React, { CSSProperties } from 'react';
import Header from "./Header";
import { useNavigate } from 'react-router-dom';

const gradientTextStyle: CSSProperties = {
  color: "#FFFFFF",
  fontSize: "36px",
  fontFamily: "Inter, sans-serif", 
  fontWeight: 600,
  marginBottom: "10px",
  marginTop: "30px", 
};

const container: CSSProperties = {
  background: "#0A031A",
  height: "calc(100vh - 60px)",
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingBottom: "100px",
  boxSizing: "border-box",
}

const buttonStyle: CSSProperties = {
  padding: '12px 16px',
  fontFamily:"Inter, sans-serif",
  border: 'none',
  width: '200px',
  background: '#7743DB',
  borderRadius: '4px',
  cursor: 'pointer',
  fontWeight: 700,
  fontSize: '14px',
  color: "white"
};

const buttonGroupStyle: CSSProperties = {
  display: 'flex',
  marginTop: "10px",
  marginRight: '10px',
  alignItems: 'center',
};

const Landing = () => {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate('/login')
  }
  return (
    <div style={{height: "100%", width: "100%"}}>
    <Header />
      <div style={container}>
        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
          <h1 style={gradientTextStyle}>Podcast Creation Tools</h1>
          <div style={buttonGroupStyle}>
            <button onClick={goToLogin}style={buttonStyle}>Login</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
