import React, { useState, useCallback, CSSProperties } from "react";
import Header from "./Header";
import { debounce, updateStateAndLocalStorage } from "./utilities/utility";
import { fetchTranscript } from "./client/fetchTranscript";
import { savePodcast } from "./client/savePodcast";
import { combineTranscripts } from "./client/combineTranscripts";
import { createPodcast } from "./client/createPodcast";
import AddToScript from "./AddToScript";
import ManageSegment from "./ManageSegment";
import FinalizePodcast from "./FinalizePodcast";

const container: CSSProperties = {
  background: "#0A031A",
  paddingTop: "80px",
  minHeight: "calc(100vh - 60px)",
  height: "auto",
  paddingLeft: "30px",
  paddingRight: "30px",
  paddingBottom: "50px",
  boxSizing: "border-box",
};

function Home() {
  const [loading, setLoading] = useState(false);
  const [completedPodcast, setCompletedPodcast] = useState(() => {
    const savedPodcast = localStorage.getItem("completedPodcast");
    return savedPodcast ? JSON.parse(savedPodcast) : undefined;
  });
  const [podcast, setPodcast] = useState(() => {
    const savedPodcast = localStorage.getItem("podcast");
    return savedPodcast ? JSON.parse(savedPodcast) : [];
  });
  const [segment, setSegment] = useState(() => {
    const savedSegment = localStorage.getItem("segment");
    return savedSegment ? JSON.parse(savedSegment) : {
      url: "",
      prompt: podcast.length > 0 ? podcast[0].prompt : "Default",
    };
  });

  const debouncedUpdateLocalStorage = debounce((key, state) => {
    localStorage.setItem(key, JSON.stringify(state));
  }, 1000);

  const setUrl = useCallback((newUrl: string) => {
    setSegment((data: any) => ({
      ...data,
      url: newUrl,
    }));
  }, []);

  const setPrompt = useCallback((newPrompt: string) => {
    setSegment((data: any) => {
      const updatedData = { ...data, prompt: newPrompt };
      debouncedUpdateLocalStorage("segment", updatedData);
      return updatedData;
    });
  }, [debouncedUpdateLocalStorage]);

  const handleScriptChange = useCallback((script: string) => {
    setSegment((prevSegment: any) => ({
      ...prevSegment,
      transcript: script,
    }));
  }, []);

  const getSegmentTranscript = async (podcastId: string, voices?: Record<string, string>) => {
    setLoading(true);
    try {
      const transcriptResponse = await fetchTranscript(segment.url, segment.prompt);
      const fullSegmentData = {
        url: segment.url,
        prompt: segment.prompt,
        title: transcriptResponse.title,
        transcript: transcriptResponse.transcript,
        podcastId: podcastId, 
        voices: voices,
      };
      updateStateAndLocalStorage(fullSegmentData, setSegment, "segment");
    } catch (error: any) {
      alert(`Error creating podcast - ${error.response.data}`);
    } finally {
      setLoading(false);
    }
  };

  const generatePodcast = async (voices: Map<string, string>, lines: any, script: string, prompt: string, podcastId?: string) => {
    setLoading(true);
    try {
      const data = await createPodcast(voices, lines, script, prompt, podcastId);
      updateStateAndLocalStorage(data, setCompletedPodcast, "completedPodcast");
    } catch (error: any) {
      alert(`Error creating podcast - ${error.response.data}`);
    } finally {
      setLoading(false);
    }
  };

  const savePodcastDetails = async (podcast: any) => {
    setLoading(true);
    try {
      await savePodcast(podcast);
      resetPodcast()
    } catch (error: any) {
      alert(`Error creating podcast - ${error.response.data}`);
    } finally {
      setLoading(false);
    }
  };

  const combineSegments = useCallback(async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const finalScript = await combineTranscripts(podcast.map((x: any) => x.script));
      const completedPodcastScript = {
        transcript: finalScript,
        podcastId: podcast[0].podcastId,
        prompt: podcast[0].prompt,
        voices: podcast[0].voices,
      };
      updateStateAndLocalStorage(completedPodcastScript, setCompletedPodcast, "completedPodcast");
    } catch (error: any) {
      alert(`Error creating podcast - ${error.response.data}`);
    } finally {
      setLoading(false);
    }
  }, [podcast]);

  const resetSegment = useCallback((prompt?: any) => {
    const defaultSegment = {
      url: "",
      prompt: prompt ? prompt : podcast.length > 0 ? podcast[0].prompt : "Default",
    };
    localStorage.setItem("segment", JSON.stringify(defaultSegment));
    setSegment(defaultSegment);
  }, [podcast])

  const deleteSegment = useCallback(
    (segment: any) => {
      const updatedPodcasts = podcast.filter((x: any) => x.url !== segment.url);
      localStorage.setItem("podcast", JSON.stringify(updatedPodcasts));
      resetSegment()
      setPodcast(updatedPodcasts);
    },
    [podcast, resetSegment]
  );

  const resetPodcast = useCallback(() => {
    localStorage.removeItem("podcast");
    localStorage.removeItem("finalScript");
    localStorage.removeItem("completedPodcast");
    resetSegment("Default")
    setPodcast([]);
    setCompletedPodcast(undefined);
  }, [resetSegment]);

  const resetAudio = useCallback(() => {
    const data = {
      transcript: completedPodcast.transcript,
    };
    updateStateAndLocalStorage(data, setCompletedPodcast, "completedPodcast");
  }, [completedPodcast]);

  const addToPodcast = useCallback(() => {
    console.log(segment)
    const newSegment = {
      url: segment.url,
      prompt: segment.prompt,
      script: segment.transcript,
      title: segment.title,
      podcastId: segment.podcastId,
      voices: segment.voices
    };
    const updatedPodcasts = [...podcast, newSegment];
    localStorage.setItem("podcast", JSON.stringify(updatedPodcasts));
    setPodcast(updatedPodcasts);
    resetSegment(segment.prompt)
  }, [podcast, resetSegment, segment]);

  return (
    <div>
      <Header />
      <div style={container}>
        <div style={{ marginBottom: "30px" }}>
          {!segment?.transcript && !completedPodcast && (
            <AddToScript
              podcast={podcast}
              segment={segment}
              setPrompt={setPrompt}
              setUrl={setUrl}
              loading={loading}
              startNewPodcastTranscript={getSegmentTranscript}
              combineSegments={combineSegments}
              resetPodcast={resetPodcast}
              deleteSegment={deleteSegment}
            />
          )}
          {segment?.transcript?.length > 0 && (
            <ManageSegment
              loading={loading}
              segment={segment}
              deleteSegment={deleteSegment}
              addToPodcast={addToPodcast}
              handleScriptChange={handleScriptChange}
            />
          )}
          {completedPodcast && (
            <FinalizePodcast
              createPodcast={generatePodcast}
              setCompletedPodcast={setCompletedPodcast}
              resetAudio={resetAudio}
              loading={loading}
              metadata={completedPodcast}
              save={savePodcastDetails}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
