import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import { API_BASE_URL_API_BACKEND } from '../utilities/constants';

interface SaveResponse {
    id: string;
}

interface Podcast {
    category: string;
    style: Style;
    coverArtUrl: string;
    episodes: Episode[];
  }
  
  interface Episode {
    audioUrl: string;
    tags: string[];
    title: string;
    summary: string;
    transcript: string;
  }
  
  interface Style {
    voices: Record<string, string>
    prompt: string;
  }

export const savePodcast = async (podcast: Podcast[]): Promise<string> => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not authenticated');
  }

  const idToken = await getIdToken(user);
  const headers = { Authorization: `Bearer ${idToken}` };

  const response = await axios.post<SaveResponse>(
    `${API_BASE_URL_API_BACKEND}/podcast`, 
    { podcast }, 
    { headers }
  );

  return response.data.id;
};