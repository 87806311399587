import axios from "axios";
import { getAuth, getIdToken } from 'firebase/auth';
import {
  API_BASE_URL_TTS,
  API_BASE_URL_SUMMARIZER_BACKEND,
} from "../utilities/constants";
import { mapToObject } from "../utilities/utility";

interface AudioResponse {
  url: string;
}

interface MetadataResponse {
  title: string;
  cover_art: string;
  tags: string[];
  summary: string;
  category: string;
  podcastTitle: string;
  podcastSummary: string;
}

interface CreatePodcastResponse {
  audioUrl: string;
  title: string;
  coverArtUrl: string;
  tags: string[];
  summary: string;
  category: string;
  transcript: string;
  podcastTitle: string;
  podcastSummary: string;
  voices: Record<string, string>;
  prompt: string;
  podcastId?: string
}

export const createPodcast = async (
  voices: Map<string, string>,
  lines: string,
  script: string,
  prompt: string,
  podcastId?: string
): Promise<CreatePodcastResponse> => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error("User not authenticated");
  }

  const idToken = await getIdToken(user);
  const headers = { Authorization: `Bearer ${idToken}` };
  const voicesObject = mapToObject(voices)
  const audioRequestBody = { script: lines, voices: voicesObject };
  const metaDataRequestBody = { 
    script, 
    ...(podcastId !== undefined && { podcastId: podcastId })
  };

  const audioPromise = axios.post<AudioResponse>(
    `${API_BASE_URL_TTS}/audio`,
    audioRequestBody,
    { headers }
  );
  const metadataPromise = axios.post<MetadataResponse>(
    `${API_BASE_URL_SUMMARIZER_BACKEND}/metadata`,
    metaDataRequestBody,
    { headers }
  );

  const [audio_response, metadata_response] = await Promise.all([
    audioPromise,
    metadataPromise,
  ]);

  return {
    audioUrl: audio_response.data.url,
    title: metadata_response.data.title,
    coverArtUrl: metadata_response.data.cover_art,
    tags: metadata_response.data.tags,
    summary: metadata_response.data.summary,
    category: metadata_response.data.category,
    podcastTitle: metadata_response.data.podcastTitle,
    podcastSummary: metadata_response.data.podcastSummary,
    transcript: script,
    voices: voicesObject,
    prompt: prompt,
    podcastId: podcastId
  };
};
