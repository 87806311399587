import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import { API_BASE_URL_API_BACKEND, API_BASE_URL_SUMMARIZER_BACKEND } from '../utilities/constants';

interface ScriptResponse {
    transcript: string;
}

interface ScrapeResponse {
    title: string;
    content: string;
}

interface FetchTranscriptResponse {
    transcript: string;
    title: string;
}

// Function to handle API requests
export const fetchTranscript = async (url: string, prompt: string): Promise<FetchTranscriptResponse> => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not authenticated');
  }

  const idToken = await getIdToken(user);
  const headers = { Authorization: `Bearer ${idToken}` };

  const contentResponse = await axios.post<ScrapeResponse>(
    `${API_BASE_URL_API_BACKEND}/scrape`, 
    { url, return_data: true }, 
    { headers }
  );

  const scriptResponse = await axios.post<ScriptResponse>(
    `${API_BASE_URL_SUMMARIZER_BACKEND}/transcript`, 
    { content: contentResponse.data.content, prompt: prompt }, 
    { headers }
  );

  return {
    transcript: scriptResponse.data.transcript,
    title: contentResponse.data.title
  };
};