import axios from 'axios';
import { getAuth, getIdToken } from 'firebase/auth';
import { API_BASE_URL_API_BACKEND } from '../utilities/constants';


interface ScrapeResponse {
    status: string;
    id: string;
}

export const startAutomatedPipeline = async (url: string): Promise<ScrapeResponse> => {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not authenticated');
  }

  const idToken = await getIdToken(user);
  const headers = { Authorization: `Bearer ${idToken}` };

  const contentResponse = await axios.post<ScrapeResponse>(
    `${API_BASE_URL_API_BACKEND}/scrape`, 
    { url }, 
    { headers }
  );

  return contentResponse.data
};