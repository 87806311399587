import React, { CSSProperties, useCallback } from "react";
import Textarea from "./components/Textarea";

interface Segment {
  url: string;
  title: string;
  transcript: string;
}

interface ManageSegmentProps {
  loading: boolean;
  segment: Segment;
  addToPodcast: () => void;
  deleteSegment: (segment: Segment) => void;
  handleScriptChange: (text: string) => void;
}

const buttonStyle: CSSProperties = {
  padding: "12px 8px",
  fontFamily: "Inter, sans-serif",
  border: "none",
  width: "100%",
  background: "#7743DB",
  borderRadius: "6px",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "14px",
  color: "white",
};

const flexEndContainer: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  width: "100%",
  marginTop: "18px",
};

const buttonStyleLeft: CSSProperties = {
  padding: "12px 8px",
  fontFamily: "Inter, sans-serif",
  width: "100%",
  background: "white",
  marginRight: "10px",
  borderRadius: "6px",
  cursor: "pointer",
  fontWeight: 700,
  fontSize: "14px",
  color: "black",
  border: "1px solid black",
};

const container: CSSProperties = {
  display: "flex",
  alignItems: "center",
  maxWidth: "1200px",
  margin: "0 auto",
  backgroundColor: "#FFFFFF",
  padding: "15px 25px",
  borderRadius: "6px",
  boxShadow: "0 5px 15px rgba(0,0,0,0.08)",
};

const textBig: CSSProperties = {
  fontSize: "15px",
  fontFamily: "Inter, sans-serif",
  fontWeight: "600",
  marginBottom: "4px",
};

const baseContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
};

function ManageSegment({
  addToPodcast,
  segment,
  deleteSegment,
  handleScriptChange,
}: ManageSegmentProps) {

  const handleSegmentDelete = useCallback(() => {
    deleteSegment(segment)
  }, [segment, deleteSegment]);

  return (
    <div style={container}>
      <div style={baseContainer}>
        <div style={textBig}>Segment Info</div>
        <Textarea
          text={segment.transcript}
          setText={(e) => handleScriptChange(e)}
        />
        <div
          style={flexEndContainer}>
          <button onClick={handleSegmentDelete} style={buttonStyleLeft}>
            Delete Segment
          </button>
          <button onClick={addToPodcast} style={buttonStyle}>
            Add To Podcast
          </button>
        </div>
      </div>
    </div>
  );
}

export default ManageSegment;
