import Landing from "./Landing";
import React from 'react';
import Login from './Login';
import Home from './Home';
import Pipeline from './Pipeline';
import { BrowserRouter as Router} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
 
function App() {
 
  return (
    <Router>
      <div>
        <section>                              
            <Routes>                                                                        
               <Route path="/" element={<Landing/>}/>
               <Route path="/login" element={<Login/>}/>
               <Route path="/home" element={<Home/>}/>
               <Route path="/pipeline" element={<Pipeline/>}/>
               <Route path="/forgot-password" element={<ForgotPassword/>}/>
               <Route path="/reset-password" element={<ResetPassword/>}/>
            </Routes>                    
        </section>
      </div>
    </Router>
  );
}
 
export default App;