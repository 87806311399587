import React, { useState } from "react";
import styled from "styled-components";

const StyledSelect = styled.select`
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
  font-weight: 500;
  font-family: "Inter, sans-serif";
  border: 1px solid;

  &:focus-visible {
    outline: #3C1A80 auto 1px;
  }
`;

const StyledOption = styled.option``;

interface DropdownOption<T> {
  name: string;
  item: T;
}

interface DropdownProps<T> {
  options: DropdownOption<T>[];
  onSelect: (item: T, value?: string) => void;
  value?: string;
}

function Dropdown<T>({ options, onSelect, value }: DropdownProps<T>) {
  const [selectedValue, setSelectedValue] = useState<
    DropdownOption<T> | undefined
  >();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedItem = options.find(
      (option) => option.name === event.target.value
    );
    setSelectedValue(selectedItem);
    if (selectedItem) {
      onSelect(selectedItem.item, value);
    }
  };
  return (
    <StyledSelect
      onChange={handleChange}
      value={selectedValue ? selectedValue.name : ""}
    >
      {options.map((option, index) => (
        <StyledOption key={index} value={option.name}>
          {option.name}
        </StyledOption>
      ))}
    </StyledSelect>
  );
}

export default Dropdown;
